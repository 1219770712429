import React, {Suspense} from "react";
import {route} from "../../modulesCompiler";
import {Redirect} from "react-router-dom";
import {providerCheckAdmin} from "../adminProvider";

//import CmsLayout from "../views/layout/CmsLayout";
const CmsLayout = React.lazy(() => import("../views/layout/CmsLayout"));

const AdminAuthenticatedMiddleware = ({ component: Component, ...rest }) => {
    const adminToken = providerCheckAdmin();
    const loginRedirect = route("cms.login");
    if(!adminToken)  {
        return <Redirect to={loginRedirect} />;
    }

    return <Suspense fallback={<div> Loading.... </div>}>
        <CmsLayout {...{component: Component, ...rest}} />
    </Suspense>
};

export default AdminAuthenticatedMiddleware;
