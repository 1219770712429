import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {DELETE_STORAGE, GET_STORAGE, GET_STORAGE_QUERY} from "../../graphql/storage";
import AddFileForm from "../uploader/AddFileForm";
import {widget} from "../../../modulesCompiler";
import toast from "react-hot-toast";

const Storage = ({callback, close}) => {
    const [deleteStorage, deleteResponse] = useMutation(DELETE_STORAGE);
    const [selected, setSelected] = useState([]);
    const { data, loading, error, refetch, networkStatus, variables, previousData } = useQuery(GET_STORAGE, {
        variables: {first: 100, page: 1},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    if(loading && networkStatus === 1) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const storage = holder ? holder[GET_STORAGE_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_STORAGE_QUERY].paginatorInfo : {};
    const changeForm = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if(value === "") value = undefined;
        refetch({[name]: value, page: 1});
    }
    const toggleSelected = (item) => {
        const find = selected.find(select => select.id === item.id);
        if(!find)       {
            setSelected([...selected, ...[item]]);
        }
        else        {
            const newSelected = selected.filter(select => select.id !== item.id);
            setSelected(newSelected);
        }
    }
    const selectItems = () => {
        if(selected.length === 0) return false;
        callback(selected);
        if(close) close();
    }
    const deleteItems = () => {
        var r = window.confirm("Želite da obrišete fajlove?");
        if (r !== true) {
            return false;
        }
        if(selected.length === 0) return false;
        const ids = selected.map(item => item.id);
        deleteStorage({variables: {ids: ids}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const selectedIds = selected.map(item => item.id);
    return (
        <div className="container-fluid">
            <div className="mb-3">
                <AddFileForm refetch={refetch} />
            </div>

            <div className="mb-3 row">
                <div className="col-md-4">
                    <input value={variables.search || ""} name="search" onChange={changeForm}
                           type="text" placeholder="Pretraga..." className="form-control shadow-sm" />
                </div>
                <div className="col-md-4">
                    <div className="btn-toolbar" role="toolbar">
                        <div className="btn-group shadow-sm mr-2" role="group" aria-label="First group">
                            {[{name: "Sve", value: ""}, {name: "Slike", value: "image"}, {name: "Fajlovi", value: "file"}].map(item => {
                                return <button key={item.name} type="button" onClick={changeForm} name="bucket" value={item.value}
                                       className={`btn ${(variables.bucket || "") === item.value ? "btn-primary" : "btn-light"}`}>
                                    {item.name}
                                </button>
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 text-end">
                    {storage.length > 0 &&
                    widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
                </div>
            </div>

            {storage.length === 0 &&
            <div className="p-5 text-center"> Prazna pretraga </div>}

            <div className="row row-cols-md-10 g-2 mb-3">
                {storage.map(item => {
                    const itemSelected = selectedIds.includes(item.id);
                    return <div key={item.id} className="col">
                        <div onClick={() => toggleSelected(item)}
                             className={`position-relative border-3 bg-white rounded-3 p-1 ${itemSelected && "border border-success"}`} title={item.name}>
                            <div className="bcg_image mb-1" style={{backgroundImage: `url(${widget("storage.image", item)})`}} />
                            {itemSelected &&
                            <i style={{top: 3, right: 3}} className="bi bi-check-square-fill position-absolute text-success fs-5" />}
                            <div className="overflow-hidden bg-white" style={{height: 45, wordBreak: "break-all"}}>
                                {item.name}
                            </div>
                        </div>
                    </div>
                })}
            </div>
            <div className="mb-3 text-end">
                {storage.length > 0 &&
                widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
            </div>

            {selected.length > 0 &&
            <div className="bg-white p-2 pe-4 border fixed-bottom text-end">
                {callback &&
                <button onClick={selectItems} className="btn btn-primary me-2">
                    Odaberi
                </button>}
                <button onClick={deleteItems} className="btn btn-danger">
                    Obrišite
                </button>
                <GraphQLErrorMessage error={deleteResponse.error} />
            </div>}
        </div>
    )
}

export default Storage;
