import React from "react";
import {useMutation} from "@apollo/client";
import {PlusCircle} from "react-feather";
import Nestable from "react-nestable";
import {ORDER_ITEMS} from "../../../_cms/graphql/items";
import toast from "react-hot-toast";
import {UPSERT_BLOCK_BANNER} from "../../graphql/banner";
import BlockBannerItem from "./BlockBannerItem";
import {widget} from "../../../modulesCompiler";



const BlockBanners = ({block, refetch}) => {
    const [upsertBanner] = useMutation(UPSERT_BLOCK_BANNER);
    const [orderItems] = useMutation(ORDER_ITEMS);

    const changedOrder = (items) => {
        orderItems(
            {variables: {items: JSON.stringify(items.items), table: "block_banners"}}
        ).then(response => {
            toast.success("Promenjen raspored!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const addBanner = () => {
       upsertBanner({variables: {block_id: block.id}}).then(response => {
           toast.success("Dodato!");
           refetch();
        }).catch(error => {
           toast.error(error.message);
        });
    }

    return (
        <div className="container">
            <button onClick={addBanner} className="btn btn-success btn-sm mb-2">
                <PlusCircle size={16} /> Dodajte baner
            </button>

            <Nestable
                collapsed={false}
                maxDepth={1}
                onChange={changedOrder}
                handler={widget("cms.DragHandler")}
                items={block.banners}
                renderItem={(props) => <BlockBannerItem key={props.item.id} {...props} refetch={refetch} />} />
        </div>
    )
}

export default BlockBanners;
