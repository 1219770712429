
function GraphQLErrorField({error, field}) {
    if(!error?.graphQLErrors)  {
        return null;
    }
    if(!error?.graphQLErrors[0]?.extensions?.validation)  {
        return null;
    }
    if(!error.graphQLErrors[0].extensions.validation[field])        {
        return null;
    }
    return (
        <>
            <div className="alert alert-danger mt-2">
                {error.graphQLErrors[0].extensions.validation[field]}
            </div>
        </>
    )
}
export default GraphQLErrorField;
