import React, {useState} from "react";
import {randString} from "../../../../app/helpers";
import Uploader from "./Uploader";
import {PlusCircle} from "react-feather";

const AddFileForm = ({refetch}) => {
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);

    const addFiles = (e) => {
        if (e.target.files.length === 0) {
            alert('Niste uneli fajl.');
            return false;
        }
        for(let i=0; i<e.target.files.length; i++) {
            e.target.files[i].id = randString();
            console.log(e.target.files[i])
        }
        setFiles([...files, ...Object.values(e.target.files)]);
        e.target.value = "";
    }

    return (
        <div>
            {!open &&
            <button onClick={() => setOpen(!open)} className= "btn btn-success">
                <PlusCircle size={16} /> Dodajte fajl
            </button>}
            {open &&
            <div className="bg-white shadow mb-4 mt-3 p-3 rounded border border-success border-3">
                <div className="mb-4">
                    <h5 className="mb-2"> Kliknite da dodate fajl: </h5>
                    <input type="file" multiple name="file" onChange={addFiles} />
                </div>

                {files.length > 0 &&
                <div className="mb-4 d-flex">
                    {files.map((item) => {
                        return <Uploader key={item.id} file={item} refetch={refetch} />
                    })}
                </div>}

                <button onClick={() => setOpen(false)} type="button" className="btn btn-light">
                    Zatvorite
                </button>
            </div>}
        </div>
    )
}

export default AddFileForm;
