import React from "react";


const Dashboard = (props) => {

    return (
        <div className="container-fluid">
            DASHBOAR CMS
        </div>
    )
}

export default Dashboard;
