import React, {Suspense} from "react";
import {Redirect} from "react-router-dom";
import {route} from "../../modulesCompiler";
import {cmsEntryPoint} from "../config";
import {providerCheckAdmin} from "../adminProvider";

//import AuthLayout from "../views/layout/AuthLayout";
const AuthLayout = React.lazy(() => import("../views/layout/AuthLayout"));

const AdminNotAuthenticatedMiddleware = ({ component: Component, ...rest }) => {
    const adminToken = providerCheckAdmin();
    const dashboardRedirect = route(cmsEntryPoint) || route("cms.dashboard");
    if(adminToken)  {
        return <Redirect to={dashboardRedirect} />
    }
    return  <Suspense fallback={<div> Loading not... </div>}>
        <AuthLayout {...{component: Component, ...rest}} />
    </Suspense>
};

export default AdminNotAuthenticatedMiddleware;
