import {useMutation} from "@apollo/client";
import {CREATE_ADMIN} from "../../graphql/admin";
import React, {useState} from "react";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import {PlusCircle} from "react-feather";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";


const AddAdministratorForm = ({refetchAdmins}) => {
    const [open, setOpen] = useState(false);
    const defaultForm = {name: "", lastname: "", email: "", password: ""};
    const [form, setForm] = useState(defaultForm);
    const [createAdmin, {error, loading}] = useMutation(CREATE_ADMIN);

    const changeField = (e) => {
        const name = e.target.name;
        setForm({...form, [name]: e.target.value});
    }

    const submit = (e) => {
        e.preventDefault();
        createAdmin({variables: form}).then(response => {
            setForm(defaultForm);
            refetchAdmins();
            setOpen(false);
        }).catch(error => {});
    }

    return (
        <div>
            <div className="mb-3">
                {!open &&
                <button onClick={() => setOpen(!open)} className="btn btn-success">
                    <PlusCircle size={16} /> Dodajte administratora
                </button>}
                {open &&
                <form onSubmit={submit} className="bg-white shadow mb-4 mt-3 p-3 rounded border border-success border-3">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <b>Ime</b>
                                <input type="text" name="name" value={form.name} onChange={changeField} className="form-control" />
                                <GraphQLErrorField error={error} field="name" />
                            </div>
                            <div>
                                <b>Prezime</b>
                                <input type="text" name="lastname" value={form.lastname} onChange={changeField} className="form-control" />
                                <GraphQLErrorField error={error} field="lastname" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <b>Email</b>
                                <input type="email" name="email" value={form.email} onChange={changeField} required className="form-control" />
                                <GraphQLErrorField error={error} field="email" />
                            </div>
                            <div>
                                <b>Lozinka</b>
                                <input type="password" name="password" value={form.password} onChange={changeField} required className="form-control" />
                                <GraphQLErrorField error={error} field="password" />
                            </div>
                        </div>
                    </div>
                    <button disabled={loading} type="submit" className="btn btn-success me-2">
                        Sacuvajte administratora
                    </button>
                    <button onClick={() => setOpen(false)} type="button" className="btn btn-light">
                        Zatvorite
                    </button>
                    <GraphQLErrorMessage error={error} />
                </form>}
            </div>

        </div>
    )
}

export default AddAdministratorForm;
