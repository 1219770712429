import React from "react";
import axios from "axios";
import {server} from "../../../../app/config";
import {CheckSquare} from "react-feather";
import {providerCheckAdmin} from "../../../_cms/adminProvider";


class Uploader extends React.Component {

    state = {
        maxSize: 12, // MB
        preload: false,
        loader: 30,
        error: ""
    }

    render = () => {
        if(!this.state.preload) return "";
        return <div className="position-relative border-primary border rounded-1 p-1 m-1">
            {this.state.loader &&
            <div className="position-absolute top-50 bg-white border-primary border rounded-2"
                 style={{height: 30, left: 5, right: 5}}>
                <div className="h-100 bg-primary" style={{width: this.state.loader + "%"}} />
            </div>}
            {!this.state.loader &&
            <div className="text-success position-absolute border-white border-3 fs-3"
                 style={{top: 0, right: 5}}>
                <CheckSquare className="bg-success text-white rounded-1" />
            </div>}
            <div className="bcg_image" style={{width: 140, backgroundImage: `url(${this.state.preload})`}} />
            {this.state.error &&
            <div className="alert alert-danger w-100">{JSON.stringify(this.state.error)}</div>}
        </div>
    };

    componentDidMount() {
        const file = this.props.file;

        let size = file.size/1000000;  // BYTES to MEGABYTES
        if(size > this.state.maxSize) {
            alert(`Fajl ${file.name} je veci od ${this.state.maxSize} MB`);
            return false;
        }
        // SET PRELOAD
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target.result) {
                const src = file.type.startsWith("image") ? e.target.result : server("storage/000-file.png");
                this.setState({...this.state, preload: src});
            }
        }
        reader.readAsDataURL(file);

        // UPLOAD
        var formData = new FormData();
        formData.append("file", file, file.name);
        const token = providerCheckAdmin();

        axios({
            method: "POST",
            url: server("files"),
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            data: formData,
            headers: { authorization: `Bearer ${token}` },
            onUploadProgress: progressEvent => {
                let previewProcess = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({...this.state, loader: previewProcess});
                if(previewProcess === 100 && this.state.preload)  {

                }
            }
        }).then((response) => {
            this.setState({...this.state, loader: false});
            this.props.refetch();
        }).catch(error => {
            this.setState({...this.state, error: error});
        });
    }
}

export default Uploader;
