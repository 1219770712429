
function GraphQLErrorMessage({error}) {
    if(!error)  {
        return null;
    }
    if(!error.graphQLErrors)  {
        return null;
    }
    if(error.graphQLErrors.length === 0)  {
        if(error.message)   {
            return <div className="alert alert-danger mt-2">
                {error.message}
            </div>
        }
        return null;
    }
    if(error?.graphQLErrors[0]?.extensions?.validation)  {
        return null;
    }

    return (
        <>
            {error.graphQLErrors.map((err, i) => {
                return <div key={i} className="alert alert-danger mt-2">
                    {err.message}
                </div>
            })}
        </>
    )
}
export default GraphQLErrorMessage;
