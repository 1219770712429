import React from "react";

function Loader(props) {

    let w = 40;
    let h = 40;
    let height;
    if (props.width) {
        height = "";
        w = props.width;
        h = props.width;
    }
    height = props.height ? props.height : 300;

    return (
        <div className={`d-flex justify-content-center align-items-center ${props.full ? "vh-100" : ""}`}
             style={{height: height, opacity: props.opacity || 1}}>
            <div className="text-center">
                <div className="d-flex justify-content-center mb-1">
                    <div className="loader" style={{width: w, height: h}}/>
                </div>
            </div>
        </div>
    )
}

export default Loader;
