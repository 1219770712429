import React from "react";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_ADMINS, GET_ADMINS_QUERY} from "../../graphql/admin";
import AdministratorItem from "./AdministratorItem";
import AddAdministratorForm from "./AddAdministratorForm";



const Administrators = (props) => {
    const { data, loading, error, refetch } =  useQuery(GET_ADMINS);
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const administrators = data[GET_ADMINS_QUERY];

    return (
        <div className="container">
            <AddAdministratorForm refetchAdmins={refetch} />

            {administrators.map(item => {
                return <AdministratorItem key={item.id} item={item} refetchAdmins={refetch} />
            })}
        </div>
    )
}

export default Administrators;
